import React from 'react';

import withGooglePlaces from 'dpl/decorators/withGooglePlaces';
import MobileShelterResultsList from 'dpl/search/components/MobileShelterResultsList';

function MobileShelterResultsContainer(props) {
  return <MobileShelterResultsList {...props} />;
}

export default withGooglePlaces(MobileShelterResultsContainer);
