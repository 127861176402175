import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import {
  ORDERED_SEARCH_TYPES,
  ORDERED_SEARCH_TYPE_VALUES
} from 'dpl/shared/constants/shared';
import TransitionMenu from 'dpl/common/components/TransitionMenu';
import BreedSearchResultsContainer from 'dpl/containers/BreedSearchResultsContainer';
import MobileShelterResultsContainer from 'dpl/search/containers/MobileShelterResultsListContainer';

export default class GlobalMobileMenuSearchMenu extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    selectedSearchType: PropTypes.string,
    openSearchBreedersMenu: PropTypes.func.isRequired,
    openSearchSheltersMenu: PropTypes.func.isRequired
  };

  static defaultProps = {
    isOpen: false,
    selectedSearchType: ORDERED_SEARCH_TYPES.BREEDERS
  };

  state = {
    query: ''
  };

  inputRef = createRef();

  handleQueryChange = e => {
    this.setState({ query: e.target.value });
  };

  handleSearchTypeChange = (e, selectedIdx) => {
    const { openSearchBreedersMenu, openSearchSheltersMenu } = this.props;

    this.setState({ query: '' });

    if (selectedIdx === 0) {
      openSearchBreedersMenu();
    } else {
      openSearchSheltersMenu();
    }
  };

  render() {
    const { isOpen, selectedSearchType } = this.props;
    const { query } = this.state;

    return (
      <div className="GlobalMobileMenuSearchMenu h-100 pt4">
        <div className="bb b--light-gray">
          <TransitionMenu
            items={ORDERED_SEARCH_TYPE_VALUES}
            onItemClick={this.handleSearchTypeChange}
            selectedIdx={ORDERED_SEARCH_TYPE_VALUES.indexOf(selectedSearchType)}
            equalWidths
            itemClassName="pb3 fw-medium purple"
          />
        </div>
        <div className="pv6 bb b--light-gray ph6">
          <input
            ref={this.inputRef}
            type="text"
            value={query}
            onChange={this.handleQueryChange}
            placeholder={
              selectedSearchType === ORDERED_SEARCH_TYPES.BREEDERS
                ? 'Enter a breed, e.g. “Pomeranian”'
                : 'Enter your zip code or city'
            }
            className="b--none w-100"
            aria-label="breed search"
          />
        </div>
        <div className="ph6 pb6 pt3 bg-near-white min-h-100">
          {selectedSearchType === ORDERED_SEARCH_TYPES.BREEDERS && (
            <BreedSearchResultsContainer isOpen={isOpen} query={query} />
          )}
          {selectedSearchType === ORDERED_SEARCH_TYPES.SHELTERS && (
            <MobileShelterResultsContainer isOpen={isOpen} query={query} />
          )}
        </div>
      </div>
    );
  }
}
