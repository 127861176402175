/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  BREEDER_EDIT_PROFILE_PATH,
  BREEDER_SETTINGS_ACCOUNT_PATH,
  BREEDER_CENTER_PATH,
  BREEDER_DOWNLOADS_PATH,
  BREEDER_REFERRAL_LINKS_PATH,
  ROOT_HOST
} from 'dpl/shared/constants/urls';
import Icon from 'dpl/common/components/Icon';
import SmartLink from 'dpl/components/SmartLink';
import NotificationCount from 'dpl/common/components/NotificationCount';
import UserMobileHeaderDropdownTopSection from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/UserMobileHeaderDropdownTopSection';
import useDashboardRoutes from 'dpl/breeder_dashboard/hooks/useDashboardRoutes';
import { copyToClipboard } from 'dpl/shared/utils';
import useToastNotifications from 'dpl/shared/hooks/useToastNotifications';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import isFeatureEnabled from 'dpl/util/isFeatureEnabled';
import { FEATURES } from 'dpl/shared/constants/features';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';

export function BreederMobileHeaderDropdownLoggedInContentLink({
  href,
  className,
  icon,
  isReal,
  onClick,
  title,
  notificationsCount,
  locked
}) {
  let Tag;
  if (isReal) {
    Tag = 'a';
  } else if (locked) {
    Tag = 'p';
  } else {
    Tag = SmartLink;
  }

  return (
    <li key={href}>
      <Tag
        className={classnames(
          'BreederMobileHeaderDropdownLoggedInContent__action flex items-center font-16',
          className
        )}
        onClick={locked ? null : onClick}
        href={locked ? null : href}
        to={locked ? null : href}
      >
        <span className="mr3">
          <Icon
            className="BreederMobileHeaderDropdownLoggedInContent__icon"
            name={icon}
            width="24px"
            height="24px"
          />
        </span>
        <span className="lh-title">{title}</span>
        {notificationsCount > 0 && (
          <NotificationCount
            className="ml3"
            count={notificationsCount}
            unreadColor="midnight"
          />
        )}

        {locked && (
          <Icon className="ml4" name="lock-16" width="16px" height="16px" />
        )}
      </Tag>
    </li>
  );
}

BreederMobileHeaderDropdownLoggedInContentLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isReal: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  notificationsCount: PropTypes.number,
  locked: PropTypes.bool
};

BreederMobileHeaderDropdownLoggedInContentLink.defaultProps = {
  className: null,
  isReal: false,
  notificationsCount: null,
  locked: false,
  href: null
};

export default function BreederMobileHeaderDropdownLoggedInContent({
  onLinkClick,
  onLogoutClick,
  profilePath,
  userData
}) {
  const { pathname } = window.location;
  const dashboardRoutes = useDashboardRoutes();
  const { pushToastNotification } = useToastNotifications();
  const currentUser = useCurrentUser();

  function handleShareProfileClick() {
    const profileUrl = `${ROOT_HOST}${profilePath}`;
    if (window.navigator.share) {
      window.navigator.share({
        title: 'Check out my profile on Good Dog! 🐶',
        text: 'Learn more about me, my dogs, and my breeding program on Good Dog:',
        url: profileUrl
      });
    } else {
      copyToClipboard(`${ROOT_HOST}${profilePath}`).then(() => {
        pushToastNotification({
          message: 'Profile URL copied to clipboard.',
          type: TOAST_NOTIFICATIONS_TYPES.SUCCESS
        });
      });
    }
    onLinkClick();
  }

  const isReferralLinksEnabled =
    isFeatureEnabled(FEATURES.REL_BREEDER_REFERRAL_LINKS) &&
    currentUser.value.data.published;

  const PERSONAL_LINKS = [
    {
      href: BREEDER_EDIT_PROFILE_PATH,
      icon: 'fetch-edit-alt',
      title: 'Edit profile',
      isShown: true
    },
    {
      href: profilePath,
      icon: 'fetch-view',
      title: 'View profile',
      isReal: true,
      isShown: true
    },
    {
      href: BREEDER_REFERRAL_LINKS_PATH,
      icon: 'fetch-link',
      title: 'Referral links',
      isShown: isReferralLinksEnabled
    },
    {
      icon: 'fetch-share',
      title: 'Share profile',
      onClick: handleShareProfileClick,
      isShown: !isReferralLinksEnabled
    },
    {
      href: BREEDER_SETTINGS_ACCOUNT_PATH,
      icon: 'fetch-settings',
      title: 'Settings',
      isShown: true
    },
    {
      href: BREEDER_DOWNLOADS_PATH,
      icon: 'fetch-download',
      title: 'Downloads',
      isShown: userData.published
    },
    {
      href: BREEDER_CENTER_PATH,
      icon: 'fetch-info',
      title: 'Good Breeder Center',
      isReal: true,
      isShown: true
    }
  ].filter(link => link.isShown);

  return (
    <div className="BreederMobileHeaderDropdownLoggedInContent">
      <UserMobileHeaderDropdownTopSection />
      <ul className="BreederMobileHeaderDropdownLoggedInContent__actions pb6 mb6 bb b--light-gray fw-medium">
        {dashboardRoutes.map(
          ({ path, icon, title, notificationsCount, locked, isActive }) => (
            <BreederMobileHeaderDropdownLoggedInContentLink
              key={path}
              className={isActive ? 'midnight' : 'secondary'}
              href={path}
              icon={icon}
              onClick={onLinkClick}
              title={title}
              notificationsCount={notificationsCount}
              locked={locked}
            />
          )
        )}
      </ul>
      <ul className="BreederMobileHeaderDropdownLoggedInContent__actions fw-medium">
        {PERSONAL_LINKS.map(({ href, icon, title, onClick, isReal }) => (
          <BreederMobileHeaderDropdownLoggedInContentLink
            key={title}
            className={pathname.startsWith(href) ? 'midnight' : 'secondary'}
            href={href}
            icon={icon}
            isReal={isReal}
            onClick={onClick || onLinkClick}
            title={title}
            locked={false}
          />
        ))}
        <li className="flex">
          <a
            className="pv3 flex secondary"
            onClick={e => {
              e.preventDefault();
              onLogoutClick();
            }}
            role="button"
          >
            <span className="mr3">
              <Icon
                className="BreederMobileHeaderDropdownLoggedInContent__icon"
                name="fetch-logout-web"
              />
            </span>
            <p className="flex-auto font-16">Log out</p>
          </a>
        </li>
      </ul>
    </div>
  );
}

BreederMobileHeaderDropdownLoggedInContent.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  profilePath: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    first_name: PropTypes.string,
    name: PropTypes.string,
    profile_photo_url: PropTypes.string,
    published: PropTypes.bool
  }).isRequired
};
