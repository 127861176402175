import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DESCRIPTIVE_BADGE_VARIANTS } from 'dpl/common/utils/constants';
import { getShortDateString, getTimeString } from 'dpl/shared/utils/date';
import DescriptiveBadge from 'dpl/common/components/DescriptiveBadge';
import Icon from 'dpl/common/components/Icon';

import {
  ITEM_TYPES,
  ITEM_TYPE_TO_ICON,
  ITEM_TYPE_TO_BG_COLOR,
  ITEM_TYPE_TO_COLOR
} from '../utils/constants';

export default function NewsItem({ item, isLastItem }) {
  const ICON_SIZE = '48px';
  const { has_seen_item: hasSeenItem } = item;

  const startTime = item.can_rsvp && new Date(item.event_start_at);

  return (
    <div className="BreederNewsNewsItem">
      <div
        className={classnames('flex pv6', { 'bb b--gray-300': !isLastItem })}
      >
        <div className="mr3 relative">
          {!hasSeenItem && (
            <span className="pv1 ph1 bg-royal-blue db absolute br-100 left-0 top-0 ba bw1 b--white" />
          )}
          <a href={item.details_url}>
            <Icon
              className={classnames(
                'pv3 ph3 br-100',
                ITEM_TYPE_TO_COLOR[item.type],
                ITEM_TYPE_TO_BG_COLOR[item.type]
              )}
              name={ITEM_TYPE_TO_ICON[item.type]}
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          </a>
        </div>
        <div className={classnames({ secondary: hasSeenItem })}>
          <p className="ttc mb1 font-12 fw-medium">{item.type}</p>
          <div className="mb2 font-16 lh-1">
            <a href={item.details_url} className="underline-hover fw-medium">
              {item.title}
            </a>
            {startTime && (
              <p>
                {getShortDateString(startTime)}, {getTimeString(startTime)}
              </p>
            )}
          </div>
          {item.can_rsvp && (
            <div className="flex fw-medium">
              <a href={item.event_rsvp_url} className="mr1">
                <DescriptiveBadge
                  label="RSVP"
                  variant={DESCRIPTIVE_BADGE_VARIANTS.PRIMARY}
                  size="small"
                />
              </a>
              <a href={item.details_url}>
                <DescriptiveBadge label="Learn More" size="small" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

NewsItem.propTypes = {
  item: PropTypes.shape({
    can_rsvp: PropTypes.bool,
    event_rsvp_url: PropTypes.string,
    event_start_at: PropTypes.string,
    details_url: PropTypes.string,
    cover_photo_url: PropTypes.string,
    title: PropTypes.string,
    has_seen_item: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ITEM_TYPES))
  }).isRequired,
  isLastItem: PropTypes.bool.isRequired
};
