import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { POPULAR_AREAS } from 'dpl/shared/constants/shelters';
import {
  ShelterResultsResultAreaItem,
  ShelterResultsResultGoogleItem
} from 'dpl/search/components/ShelterResultsList';
import SearchNoResults from 'dpl/components/Search/SearchNoResults';

export default function MobileShelterResultsList({
  googlePlacesResults,
  query
}) {
  return (
    <div className="MobileShelterSearchResultsList">
      {(() => {
        if (!query) {
          return (
            <Fragment>
              <p className="silver fw-medium mb2">Popular areas</p>
              {POPULAR_AREAS.map(area => (
                <ShelterResultsResultAreaItem key={area.url} area={area} />
              ))}
            </Fragment>
          );
        }

        return googlePlacesResults.length === 0 ? (
          <SearchNoResults query={query} />
        ) : (
          googlePlacesResults.map((place, idx) => (
            <ShelterResultsResultGoogleItem key={idx} place={place} />
          ))
        );
      })()}
    </div>
  );
}

MobileShelterResultsList.propTypes = {
  query: PropTypes.string,
  googlePlacesResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      getCoordinates: PropTypes.func.isRequired
    })
  ).isRequired
};

MobileShelterResultsList.defaultProps = {
  query: null
};
