import React from 'react';

export default function IntroBanner() {
  return (
    <div className="BreederNewsIntroBanner bg-purple white pv4 ph4">
      <p className="mb1 f1 b">
        <span className="ttu">New &mdash; </span>
        News &amp; Resources
      </p>
      <p className="f2 fw-medium">You can now find important updates here</p>
    </div>
  );
}
