import React from 'react';
import PropTypes from 'prop-types';

import { BREEDER_CENTER_PATH } from 'dpl/shared/constants/urls';
import LoadingWrapper from 'dpl/components/LoadingWrapper';

import IntroBanner from './IntroBanner';
import EvergreenContent from './EvergreenContent';
import NewsItem from './NewsItem';

export default function Layout({
  newsItems,
  evergreenContent,
  isLoading,
  isFirstTime
}) {
  return (
    <div className="BreederNewsLayout">
      <LoadingWrapper isLoading={isLoading} className="transform-center-all">
        {isFirstTime && <IntroBanner />}
        <div className="pt2 pb6">
          <div className="flex items-center ph5 pv4 bb b--hairline">
            <p className="fw-medium flex-auto font-serif font-20">Updates</p>
            <a
              href={BREEDER_CENTER_PATH}
              className="underline royal-blue font-16"
            >
              See all
            </a>
          </div>
          {newsItems.map((item, idx) => (
            <div key={item.id} className="ph5">
              <NewsItem item={item} isLastItem={idx === newsItems.length - 1} />
            </div>
          ))}
          {evergreenContent && <EvergreenContent content={evergreenContent} />}
        </div>
      </LoadingWrapper>
    </div>
  );
}

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newsItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFirstTime: PropTypes.bool,
  evergreenContent: PropTypes.string
};

Layout.defaultProps = {
  isFirstTime: null,
  evergreenContent: null
};
